var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-text-field',{attrs:{"label":_vm.$t('lbl.email'),"outlined":"","dense":"","hide-details":"auto","rules":_vm.emailRules},model:{value:(_vm.email.email),callback:function ($$v) {_vm.$set(_vm.email, "email", $$v)},expression:"email.email"}})],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[(!_vm.verifyEmail)?_c('v-tooltip',{attrs:{"color":"primary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","x-small":"","color":"primary","disabled":!_vm.isEmail(_vm.email.email) || !_vm.email.email},on:{"click":_vm.showActiveUser}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-account-plus-outline ")])],1)]}}],null,false,1783224984)},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.inviteUser')))])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","x-small":"","color":"default"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-account-off-outline ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.userActive')))])])],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[(_vm.pos === 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.addEmailContact()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)]}}],null,false,2475858443)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.insert')))])]):_vm._e()],1),(_vm.emails.length > 1)?_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.deleteEmailContacts()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)]}}],null,false,965160894)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])])],1):_vm._e(),_c('v-dialog',{attrs:{"scrollable":"","max-width":"400px"},model:{value:(_vm.showDialogActiveUser),callback:function ($$v) {_vm.showDialogActiveUser=$$v},expression:"showDialogActiveUser"}},[_c('v-card',{attrs:{"max-height":"400px"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('dashboard.inviteUser')))]),_c('v-divider'),_c('v-card-text',[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('dashboard.inviteUserMsg'))+" ")])],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.showDialogActiveUser = !_vm.showDialogActiveUser}}},[_vm._v(" "+_vm._s(_vm.$t('btn.cancele'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":"","loading":_vm.loadingModal},on:{"click":_vm.activeUser}},[_vm._v(" "+_vm._s(_vm.$t('btn.save'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }