<template>
  <fragment>
    <v-row v-if="!showRow">
      <v-col
        cols="12"
        md="11"
      >
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <p>{{ contact.name }} {{ contact.apellidos }}</p>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <p>
              <span v-if="contact.role_id">
                {{ rolsFree.filter(e => e.id === contact.role_id)[0].name }}
              </span>
            </p>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <p>
              <span v-if="contact.emails.length > 0">
                {{ contact.emails[0].email }}
                <!--<span
                  v-for="(ema, indEma) in contact.emails"
                  :key="indEma"
                > {{ ema.email }}<br /> </span>-->
              </span>
            </p>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <p>
              <span v-if="contact.phones.length > 0">
                <span v-if="contact.phones[0].code">
                  {{ `+${contact.phones[0].code}` }}
                </span>
                <span v-if="contact.phones[0].phone">
                  {{ `${contact.phones[0].phone}` }}
                </span>
                <span v-if="contact.phones[0].ext">
                  {{ ` X ${contact.phones[0].ext}` }}
                </span>
                <!--<span
                  v-for="(ema, indEma) in contact.emails"
                  :key="indEma"
                > {{ ema.email }}<br /> </span>-->
              </span>
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="1"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              fab
              outlined
              v-bind="attrs"
              v-on="on"
              @click="setShowRow"
            >
              {{ showRow ? icons.mdiMenuDown : icons.mdiMenuRight }}
            </v-icon>
          </template>
          <span>{{ showRow ? $t('lbl.hidden') : $t('lbl.show') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row v-if="showRow">
      <v-col
        cols="12"
        md="5"
      >
        <v-row>
          <v-col
            cols="12"
            md="10"
          >
            <v-text-field
              v-model="contact.name"
              :label="$t('register.namePerson')"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="1"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mt-1 mx-2"
                  fab
                  dark
                  x-small
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteContact()"
                >
                  <v-icon small>
                    {{ icons.mdiTrashCan }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('btn.delete') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <!--<v-row>
          <v-col
            cols="12"
            md="10"
          >
            <v-text-field
              v-model="contact.apellidos"
              :label="$t('lbl.apellidos')"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>-->
      </v-col>

      <v-col
        cols="12"
        md="7"
      >
        <v-row>
          <!--<v-col
            v-if="showRow"
            cols="12"
            md="10"
          >
            <v-select
              v-model="contact.role_id"
              :items="rolsFree"
              item-text="name"
              item-value="id"
              :label="$t('lbl.rol')"
              outlined
              clearable
              dense
              hide-details
            ></v-select>
          </v-col>-->
          <v-col
            cols="12"
            md="11"
          >
            <Email
              v-for="(email, indE) in contact.emails"
              :key="indE"
              :pos="indE"
              :model="model"
              :pos-contact="pos"
              :email="email"
              :emails="contact.emails"
              :list-emails="listEmails"
              @updateActiveUser="$emit('updateActiveUser')"
            />
          </v-col>
          <v-col
            cols="12"
            md="1"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  fab
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  @click="setShowRow"
                >
                  {{ showRow ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </template>
              <span>{{ showRow ? $t('lbl.hidden') : $t('lbl.show') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <!--<v-row>
          <v-col
            cols="12"
            md="12"
          >
            <Email
              v-for="(email, indE) in contact.emails"
              :key="indE"
              :pos="indE"
              :model="model"
              :pos-contact="pos"
              :email="email"
              :emails="contact.emails"
            />
          </v-col>
        </v-row>-->
        <v-row>
          <v-col
            cols="12"
            md="11"
          >
            <Phone
              v-for="(phone, indE) in contact.phones"
              :key="indE"
              :pos="indE"
              :model="model"
              :pos-contact="pos"
              :phone="phone"
              :phones="contact.phones"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider class="mt-2 mb-2"></v-divider>
  </fragment>
</template>

<script>
import { mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'
import Email from './Email.vue'
import Phone from './Phone.vue'

export default {
  components: {
    Email,
    Phone,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    contact: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    model: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    contacts: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    rols: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    listEmails: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      showRow: false,
      permisos: localStorage.getItem('permisos_auth'),
    }
  },
  computed: {
    rolsFree() {
      const arr = []
      this.rols.forEach(element => {
        if (element.slug === 'reservas' || element.slug === 'pagos-facturacion' || element.slug === 'operaciones') {
          arr.push(element)
        }
      })

      return arr
    },
  },
  created() {
    if (this.pos === 0 && this.contacts.length === 1) {
      this.showRow = true
    } else if (!this.contact.name) {
      this.showRow = true
    }
  },
  methods: {
    ...mapMutations(['deleteContactsAfiliados']),
    setShowRow() {
      this.$nextTick(() => {
        this.showRow = !this.showRow
      })
    },
    deleteContact() {
      this.model.contacts.splice(this.pos, 1)
    },

    /* getProducts() {
      this.axios
        .get('type_product?per_page=1000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.products = res.data.data
        })
    }, */
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
