<template>
  <v-row>
    <v-col
      cols="12"
      md="9"
    >
      <v-text-field
        v-model="email.email"
        :label="$t('lbl.email')"
        outlined
        dense
        hide-details="auto"
        :rules="emailRules"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      md="1"
    >
      <v-tooltip
        v-if="!verifyEmail"
        color="primary"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="primary"
            v-bind="attrs"
            :disabled="!isEmail(email.email) || !email.email"
            @click="showActiveUser"
            v-on="on"
          >
            <v-icon small>
              mdi-account-plus-outline
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('dashboard.inviteUser') }}</span>
      </v-tooltip>

      <v-tooltip
        v-else
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            x-small
            color="default"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon small>
              mdi-account-off-outline
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('dashboard.userActive') }}</span>
      </v-tooltip>
    </v-col>

    <v-col
      cols="12"
      md="1"
    >
      <v-tooltip
        v-if="pos === 0"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="addEmailContact()"
          >
            <v-icon small>
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.insert') }}</span>
      </v-tooltip>
    </v-col>
    <v-col
      v-if="emails.length > 1"
      cols="12"
      md="1"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="error"
            v-bind="attrs"
            v-on="on"
            @click="deleteEmailContacts()"
          >
            <v-icon small>
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>
    </v-col>

    <v-dialog
      v-model="showDialogActiveUser"
      scrollable
      max-width="400px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ $t('dashboard.inviteUser') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-card-text>
            {{ $t('dashboard.inviteUserMsg') }}
          </v-card-text>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="showDialogActiveUser = !showDialogActiveUser"
          >
            {{ $t('btn.cancele') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loadingModal"
            @click="activeUser"
          >
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    model: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    email: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    emails: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    posContact: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    listEmails: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      rols: [],
      products: [],
      showDialogActiveUser: false,
      loadingModal: false,
      emailRules: [v => !!v || this.$t('landing.rule1'), v => this.isEmail(v) || this.$t('landing.rule2')],
    }
  },
  computed: {
    ...mapState({
      contactsAfiliados: state => state.app.contactsAfiliados,
    }),
    verifyEmail() {
      let arr = false
      if (this.listEmails.length > 0) {
        const exist = this.listEmails.filter(e => e === this.email.email)
        if (exist.length > 0) {
          arr = true
        }
      }

      return arr
    },
  },
  methods: {
    ...mapMutations(['addEmailContactsAfiliados', 'deleteEmailContactsAfiliados']),
    isEmail(str) {
      const res = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      try {
        if (str === '' || str === null || str === undefined) {
          return true
        }
        if (res.test(str)) {
          return true
        }

        return false
      } catch (_) {
        return false
      }
    },
    addEmailContact() {
      this.model.contacts[this.posContact].emails.push({
        email: null,
      })
    },
    deleteEmailContacts() {
      this.model.contacts[this.posContact].emails.splice(this.pos, 1)
    },
    showActiveUser() {
      this.showDialogActiveUser = true
    },
    activeUser() {
      this.loadingModal = true
      const json = {
        id: this.model.id,
        email: this.email.email,
        name: this.model.contacts[this.posContact].name,
      }

      if (this.email.email) {
        this.axios
          .post('affiliate/active-user', json, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            // eslint-disable-next-line no-empty
            if (response.data.success === false) {
              this.$toast.error(this.$t('register.msgExistEmailCompany', { n: this.email.email }))
            } else {
              this.$toast.success(this.$t('msg.infoSuccess'))
              this.$emit('updateActiveUser')
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.loadingModal = false
            this.showDialogActiveUser = false
          })
      }
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
