var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[(!_vm.showRow)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"11"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('p',[_vm._v(_vm._s(_vm.contact.name)+" "+_vm._s(_vm.contact.apellidos))])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('p',[(_vm.contact.role_id)?_c('span',[_vm._v(" "+_vm._s(_vm.rolsFree.filter(function (e) { return e.id === _vm.contact.role_id; })[0].name)+" ")]):_vm._e()])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('p',[(_vm.contact.emails.length > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.contact.emails[0].email)+" ")]):_vm._e()])]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('p',[(_vm.contact.phones.length > 0)?_c('span',[(_vm.contact.phones[0].code)?_c('span',[_vm._v(" "+_vm._s(("+" + (_vm.contact.phones[0].code)))+" ")]):_vm._e(),(_vm.contact.phones[0].phone)?_c('span',[_vm._v(" "+_vm._s(("" + (_vm.contact.phones[0].phone)))+" ")]):_vm._e(),(_vm.contact.phones[0].ext)?_c('span',[_vm._v(" "+_vm._s((" X " + (_vm.contact.phones[0].ext)))+" ")]):_vm._e()]):_vm._e()])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"fab":"","outlined":""},on:{"click":_vm.setShowRow}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.showRow ? _vm.icons.mdiMenuDown : _vm.icons.mdiMenuRight)+" ")])]}}],null,false,4127223795)},[_c('span',[_vm._v(_vm._s(_vm.showRow ? _vm.$t('lbl.hidden') : _vm.$t('lbl.show')))])])],1)],1):_vm._e(),(_vm.showRow)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('v-text-field',{attrs:{"label":_vm.$t('register.namePerson'),"outlined":"","dense":"","hide-details":""},model:{value:(_vm.contact.name),callback:function ($$v) {_vm.$set(_vm.contact, "name", $$v)},expression:"contact.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1 mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.deleteContact()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)]}}],null,false,3334907182)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"11"}},_vm._l((_vm.contact.emails),function(email,indE){return _c('Email',{key:indE,attrs:{"pos":indE,"model":_vm.model,"pos-contact":_vm.pos,"email":email,"emails":_vm.contact.emails,"list-emails":_vm.listEmails},on:{"updateActiveUser":function($event){return _vm.$emit('updateActiveUser')}}})}),1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"fab":"","outlined":""},on:{"click":_vm.setShowRow}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.showRow ? _vm.icons.mdiMenuDown : _vm.icons.mdiMenuRight)+" ")])]}}],null,false,4127223795)},[_c('span',[_vm._v(_vm._s(_vm.showRow ? _vm.$t('lbl.hidden') : _vm.$t('lbl.show')))])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"11"}},_vm._l((_vm.contact.phones),function(phone,indE){return _c('Phone',{key:indE,attrs:{"pos":indE,"model":_vm.model,"pos-contact":_vm.pos,"phone":phone,"phones":_vm.contact.phones}})}),1)],1)],1)],1):_vm._e(),_c('v-divider',{staticClass:"mt-2 mb-2"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }